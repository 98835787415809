<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('organogram.designationl') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="5" lg="5" xl="5">
            <b-form-group
              class="row"
              label-cols-sm="3"
              label-for="organiation_id"
              >
                <template v-slot:label>
                  {{$t('globalTrans.organization')}}
              </template>
              <b-form-select
                plain
                v-model="search.org_id"
                :options="orgList"
                :disabled="authOrgId > 0"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="5" lg="5" xl="5">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('organogram.designationl')"
              label-for="designation"
            >
              <b-form-input
                id="designation"
                v-model="search.designation"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="button" variant="primary" @click="searchData">
              <i class="fas fa-search"></i> {{ $t('globalTrans.search')}}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('organogram.designationl') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button size="sm" v-show="searchButton" v-if="search.org_id" @click="pdfExport" class="btn_add_new mr-2">
                    {{ $t('globalTrans.export_pdf') }}
                </b-button>
                <b-button class="btn_add_new" size="sm" v-b-modal.modal-4 @click="resetId">
                    <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered striped hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(designation_bn)="data">
                      {{ data.item.designation_bn }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <!-- <div class="status_wrapper status_inactive" v-if="data.item.status" title="inactive">
                        <i class="fas fa-eye-slash"></i>
                      </div>
                      <div class="status_wrapper status_active" v-else title="active">
                        <i class="fas fa-eye"></i>
                      </div> -->
                      <b-badge pill variant="danger" v-if="data.item.status">{{ $t('globalTrans.inactive') }}</b-badge>
                      <b-badge pill variant="success" v-else>{{ $t('globalTrans.active') }}</b-badge>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 variant="iq-bg-success" size="sm" @click="edit(data.item)">
                        <i class="ri-ball-pen-fill"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action" title="Change Status" :class="data.item.status ? ' table_action_toggle' : ' table_action_status'" size="sm" @click="remove(data.item)">
                        <i class="fas" :class="data.item.status ? 'fa-toggle-off' : 'fa-toggle-on'"></i>
                      </a>
                      <!-- <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success border mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button title="Change Status" :variant="data.item.status ? ' iq-bg-danger' : ' iq-bg-success'" size="sm" @click="remove(data.item)">
                        <i class="fas" :class="data.item.status ? 'fa-toggle-off' : 'fa-toggle-on'"></i>
                      </b-button> -->
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form'
import { mapGetters } from 'vuex'
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { designationList, designationListAll, designationToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      searchButton: false,
      search: {
        designation_name: '',
        org_id: 0
      },
      designationList: [],
      designationListAll: [],
      orgName: '',
      orgNameBn: '',
      rows: [],
      authOrgId: 0
    }
  },
  computed: {
    formTitle () {
      return (this.editItemId === 0) ? this.$t('organogram.designation_entry') : this.$t('organogram.designation') + ' ' + this.$t('globalTrans.modify')
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('organogram.designation_namel'), class: 'text-center' },
          { label: this.$t('organogram.direct_post'), class: 'text-center' },
          { label: this.$t('organogram.promotional_post'), class: 'text-center' },
          { label: this.$t('organogram.total_post'), class: 'text-center' },
          { label: this.$t('organogram.grade_of_post'), class: 'text-center' },
          { label: this.$t('organogram.sorting_order'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'designation_bn' },
          { key: 'direct_post' },
          { key: 'promotional_post' },
          { key: 'total_post' },
          { key: 'grade_name_bn' },
          { key: 'sorting_order' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'designation' },
          { key: 'direct_post' },
          { key: 'promotional_post' },
          { key: 'total_post' },
          { key: 'grade_name' },
          { key: 'sorting_order' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.authOrgId = this.authUser.org_id
      this.search = Object.assign({}, this.search, {
        org_id: this.authOrgId
      })
    }
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    this.loadData()
  },
  methods: {
    searchData () {
      this.searchButton = true
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      }
      this.loadData()
      this.pdfLoadData()
    },
    remove (item) {
      this.changeStatus(commonServiceBaseUrl, designationToggleStatus, item, 'common', 'designationList')
    },
    pdfLoadData () {
        const params = Object.assign({}, this.search)
        RestApi.getData(commonServiceBaseUrl, designationListAll, params).then(response => {
            if (response.success) {
                this.designationListAll = response.data
            }
        })
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(commonServiceBaseUrl, designationList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.designationList = response.data.data
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('organogram.designation_namel'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('organogram.direct_post'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('organogram.promotional_post'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('organogram.total_post'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('organogram.grade_of_post'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
        if (this.$i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        this.designationListAll.map(item => {
            this.orgName = item.org_name
            this.orgNameBn = item.org_name_bn
        })
      var docDefinition = {
        content: [
          { text: this.$i18n.locale === 'en' ? this.orgName : this.orgNameBn, style: 'header1' },
          { text: this.$i18n.locale === 'en' ? 'Designation List' : 'উপাধির তালিকা', style: 'header' },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ['10%', '25%', '20%', '15%', '14%', 'auto'],
              body: rowData
            }
          }
        ],
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture', color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            header: {
                alignment: 'center',
                margin: [5, 0, 5, 30],
                fontSize: 16,
                bold: true
            },
            header1: {
                alignment: 'center',
                margin: [5, 0, 5, 10],
                fontSize: 20,
                bold: true
            }
        }
      }
      pdfMake.createPdf(docDefinition, null).download()
    },
    getPdfData () {
        const keys = [
            { key: 'serial_no' },
            { key: this.$i18n.locale === 'en' ? 'designation' : 'designation_bn' },
            { key: 'direct_post' },
            { key: 'promotional_post' },
            { key: 'total_post' },
            { key: this.$i18n.locale === 'en' ? 'grade_name' : 'grade_name_bn' }
        ]
        var serial = 0
        const listData = this.designationListAll.map(item => {
            serial += 1
            const rowData = keys.map((keyItem, index) => {
                if (keyItem.key === 'serial_no') {
                    return { text: this.$n(serial) }
                }
                if (keyItem.key === 'direct_post') {
                    return { text: this.$n(item.direct_post) }
                }
                if (keyItem.key === 'promotional_post') {
                    return { text: this.$n(item.promotional_post) }
                }
                if (keyItem.key === 'total_post') {
                    return { text: this.$n(item.total_post) }
                }
                if (keyItem.key === 'sorting_order') {
                    return { text: this.$n(item.sorting_order) }
                }
                if (keyItem.key === 'org_name') {
                    return { text: item.org_name }
                }
                if (keyItem.key === 'org_name_bn') {
                    return { text: item.org_name_bn }
                }
                if (keyItem.key === 'status') {
                    return { text: item.status ? this.$t('globalTrans.inactive') : this.$t('globalTrans.active') }
                }

            return { text: item[keyItem.key] }
            })

            return rowData
        })

      return listData
    }
  }
}
</script>
