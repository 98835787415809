<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="10" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <ValidationProvider name="Organiation" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="organiation_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="designation.org_id"
                          :options="orgList"
                          id="organiation_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :disabled="authOrgId > 0"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Designation Name (En)" vid="designation" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="designation_name"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('organogram.designation_name')}} <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          id="designation_name"
                          v-model="designation.designation"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Designation Name (Bn)" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="designation_bn"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('organogram.designation_name_bn')}} <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          id="designation_bn"
                          v-model="designation.designation_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Direct post" vid="direct_post" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="direct_post"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('organogram.direct_post')}} <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          type="number"
                          id="direct_post"
                          v-model="designation.direct_post"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Promotional post" vid="promotional_post" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="promotional_post"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('organogram.promotional_post')}} <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          type="number"
                          id="promotional_post"
                          v-model="designation.promotional_post"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Total post" vid="total_post">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="total_post"
                      >
                      <template v-slot:label>
                        {{$t('organogram.total_post')}} <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          disabled
                          type="number"
                          id="total_post"
                          v-model="totalPostCount"
                          ></b-form-input>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Grade Name" vid="grade_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="grade_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('organogram.grade_of_post')}} <span class="text-danger">*</span>
                      </template>
                        <b-form-select
                          plain
                          v-model="designation.grade_id"
                          :options="gradeList"
                          id="grade_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Sorting order" vid="sorting_order" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="sorting_order"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('organogram.sorting_order')}} <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          type="number"
                          id="sorting_order"
                          v-model="designation.sorting_order"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                    &nbsp;
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl } from '../../../../../config/api_config'
import { designationStore, designationUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getdesignationData()
      // Object.freeze(tmp)
      this.designation = tmp
    } else {
      this.getLastSortingOrder()
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.authOrgId = this.$store.state.Auth.authUser.org_id
      this.designation = Object.assign({}, this.designation, {
        org_id: this.authOrgId
      })
    }
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      designation: {
        designation: '',
        designation_bn: '',
        org_id: 0,
        direct_post: '',
        promotional_post: '',
        total_post: '',
        grade_id: 0,
        sorting_order: ''
      },
      authOrgId: 0
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    gradeList: function () {
      const listObject = this.$store.state.commonObj.gradeList
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
      return tmpList
    },
    totalPostCount: function () {
      const totalCal = parseInt(this.designation.direct_post) + parseInt(this.designation.promotional_post)
      return totalCal
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.designation = Object.assign({}, this.designation, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.designation = Object.assign({}, this.designation, {
        org_id: this.authUser.office_detail.org_id
      })
    }
  },
  methods: {
    getLastSortingOrder () {
        this.designationCheck = this.$store.state.commonObj.designationList.filter(item => item.sorting_order !== null)
        this.designationCheck.sort((a, b) => { return a.sorting_order - b.sorting_order })
        let lastOrder = 0
        this.designationCheck.forEach((element, key) => {
          lastOrder = element.sorting_order
        })
        this.designation.sorting_order = lastOrder + 1
    },
    getdesignationData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      this.designation.total_post = this.totalPostCount

      if (this.designation.id) {
        result = await RestApi.putData(commonServiceBaseUrl, `${designationUpdate}/${this.id}`, this.designation)
      } else {
        result = await RestApi.postData(commonServiceBaseUrl, designationStore, this.designation)
      }

      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
