// Test
export const test = '/test/list'
//  Designation
export const designationList = 'master-designation/list'
export const designationListAll = 'master-designation/list-all'
export const designationStore = 'master-designation/store'
export const designationUpdate = 'master-designation/update'
export const designationToggleStatus = 'master-designation/toggle-status'
export const designationDelete = 'master-designation/destroy'
//  Grade List
export const gradeList = 'grade-list'
//  Assign Designation
export const assignDesignationList = 'assign-designation/list'
export const assignDesignationListAll = 'assign-designation/list-all'
export const assignDesignationStore = 'assign-designation/store'
export const assignDesignationUpdate = 'assign-designation/update'
export const assignDesignationToggleStatus = 'assign-designation/toggle-status'
export const assignDesignationDelete = 'assign-designation/destroy'
export const officeDesignationsApi = '/assign-designation/office-designations'
